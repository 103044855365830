import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

const ReadingClub= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
    
         <Container maxWidth={0.88} paddingTop={'0 !important'}  >
          <Grid>
          
             <Form />
            </Grid>
      
        </Container>
       
     
    </Main>
  );
};

export default ReadingClub;
