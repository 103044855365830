/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Form = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center' fontWeight={700}>
        Reading Club
        </Typography>
        <br />
        <Typography variant='h5' fontWeight={600}>
        BridgeCourse
        </Typography>
        
        <Typography>
          classes 9 - 12     16-July-2022
        </Typography>
        <br/>
        <Typography variant={'subtitle1'} align={'justify'}>
       
        To facilitate a smoother transition and to strengthen the concepts of previous learning, a Bridging the gap session on material based learning was conducted for the students of class 1 by the teachers of Pre-Primary from Friday, 24 June 2022 to Wednesday, 29 June 2022.

The students worked in their respective classes as per the previous academic year and were engaged in hands-on learning experiences on the concepts learnt as part of the curriculum. Students had an opportunity to learn through exploration and revised their reading and writing skills with the help of materials. This provided a balanced blend of traditional and activity-based learning.

Students looked forward to an interactive session and were enthusiastic to learn through this week-long course. The Bridging the gap aimed at including the essence of honing motor skills, hand- eye -coordination, confidence and intellectual abilities of students.
       <br/><br/>
        </Typography>
        <Box border={1}>
        <Typography variant='h6' align='center' fontWeight={700}>
        Little steps in finding opportunities to meet students’ needs goes a long way in making success stories!
        </Typography> 
        </Box>
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
